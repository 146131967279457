<template>
  <div :id="uid">
    <LottiePlayer
      :file="lottieOptions.file"
      :loop="lottieOptions.loop"
      :autoplay="lottieOptions.autoplay"
      :speed="lottieOptions.speed"
      :parent-uid="uid"
    />
  </div>
</template>

<script>
import LottiePlayer from "./LottiePlayer.vue";

export default {
  name: "LottiePlayerWrapper",
  components: {
    LottiePlayer,
  },
  props: {
    lottie: {
      type: String,
    },
  },
  computed: {
    lottieOptions() {
      return JSON.parse(this.lottie);
    },
    uid() {
      return `animation-${this._uid}`;
    },
  },
};
</script>
