import Vue from "@/helper/initVue";

import LottiePlayerWrapper from "@/components/lottie/LottiePlayerWrapper.vue";

const wrapperElements = document.querySelectorAll(".lottie-player__wrapper");

Array.from(wrapperElements).forEach((wrapper: any) => {
  const lottieData = wrapper.dataset.lottie;

  new Vue({
    el: wrapper,
    render: (h) =>
      h(LottiePlayerWrapper, {
        props: {
          lottie: lottieData,
        },
      }),
  });
});
