<template>
  <div ref="lottieContainer"></div>
</template>

<script>
import Lottie from "lottie-web";

export default {
  name: "LottiePlayer",
  props: {
    file: {
      type: String,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    /**
     * parentUid: Id from the parent element
     */
    parentUid: {
      type: String,
      default: "",
    },
    startInViewport: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lottie: undefined,
      observer: this.startInViewport
        ? new IntersectionObserver(this.registerIntersection())
        : undefined,
    };
  },
  mounted() {
    this.lottie = Lottie.loadAnimation({
      container: this.$refs.lottieContainer,
      path: this.file,
      renderer: "svg",
      loop: this.loop,
      autoplay: this.autoplay,
    });
    this.$emit("onCreateLottie", this.lottie);
    this.startObserver();
  },
  methods: {
    startObserver() {
      if (!this.startInViewport || this.autoplay) {
        return;
      }
      this.observer.observe(document.querySelector(`#${this.parentUid}`));
    },
    registerIntersection() {
      const callback = (entries) => {
        const entry = entries.pop();
        if (entry.isIntersecting) {
          this.lottie.play();
          this.observer.disconnect();
        }
      };
      return callback;
    },
  },
};
</script>
